import Vue from 'vue'
import Vuex from 'vuex'
import api from '@/services/condinvest'

// Modules
import Groups from './modules/groups'
import Users from './modules/users'
import Messages from './modules/messages'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: JSON.parse(localStorage.user || '{}'),
    token: localStorage.token || undefined,
    rules: []
  },
  getters: {
    userName: state => {
      return state.user.name === undefined ? '' : state.user.name
    },
    isAdmin: (state) => state.user.groups.reduce((v, g) => v || g.key === 'Super Admin', false),
    isCobranca: (state) => state.user.groups.reduce((v, g) => v || g.key === 'Cobrança', false),
    isLogged: state => Object.keys(state.user).length !== 0 && state.token !== undefined
  },
  mutations: {
    login: (state, payload) => {
      state.user = payload.user
      state.token = payload.token
    },
    logout: state => {
      state.user = {}
      state.token = undefined
    },
    rules: (state, payload) => {
      state.rules = payload
    },
    profile: (state, payload) => {
      state.user = { ...state.user, name: payload.name, email: payload.email, telephone: payload.telephone }
    }
  },
  actions: {
    login: (context, payload) => {
      localStorage.user = JSON.stringify(payload.user)
      localStorage.token = payload.token
      api.defaults.headers.common.Authorization = `Bearer ${payload.token}`
      context.commit('login', payload)
    },
    logout: context => {
      localStorage.clear()
      delete api.defaults.headers.common.Authorization
      context.commit('logout')
    },
    rules: (context, payload) => {
      context.commit('rules', payload)
    },
    profile: (context, payload) => {
      localStorage.user = JSON.stringify({ ...JSON.parse(localStorage.user || '{}'), name: payload.name, email: payload.email, telephone: payload.telephone })
      context.commit('profile', payload)
    }
  },
  modules: {
    // auth: auth,
    groups: Groups,
    users: Users,
    messages: Messages
  }
})
