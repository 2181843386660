export const toast = (vue, variant, title, msg) => {
  vue.$bvToast.toast(msg, {
    title: title,
    variant: variant,
    solid: true
  })
}

// Convert data to array options for a select field
export const toOptions = (data, key) => {
  const options = []
  data.forEach(element => {
    options.push({
      value: element[key.value],
      text: element[key.text]
    })
  })
  return options
}
