export default {
  namespaced: true,
  state: {
    messages: []
  },
  mutations: {
    set: (state, payload) => {
      state.messages = payload
    },
    unshift: (state, payload) => {
      state.messages.unshift(payload)
    }
  },
  actions: {
    init: (context, payload) => {
      context.commit('set', payload)
    },
    add: (context, payload) => {
      context.commit('unshift', payload)
    }
  }
}
