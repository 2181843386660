<template>
    <div>
        <Menu>
            <transition name="page-transition" mode="out-in">
                <router-view @callModal="callModal"></router-view>
            </transition>
        </Menu>
        <div> <!-- Modals -->
            <Delete :item="item" :method="method" :title="'del'"/>
            <Form :fields="fields" :item="item" :meta="meta" :mode="mode" :extraFields="extraFields" :extraMode="extraMode"/>
        </div>
    </div>
</template>

<style lang="stylus" scoped>

    .page-transition-enter, .page-transition-leave-to
        //transform translateX(-30px)
        opacity 0

    .page-transition-enter-active, .page-transition-leave-active
        transition all 0.2s

</style>

<script>
import Menu from '@/components/Dashboard/Menu'

import Delete from '@/components/Modals/Delete'
import Form from '@/components/Modals/Form'

import api from '@/services/condinvest'
import { toast } from '@/utils'

export default {
  name: 'Dashboard',
  components: {
    Menu, Delete, Form
  },
  data: () => ({
    item: null,
    fields: [],
    meta: {},
    mode: '',
    extraFields: null,
    extraMode: null,
    method: undefined
  }),
  methods: {
    getRules () {
      api.get('role/index').then((response) => {
        console.log(response.data.roles)
        if (response.status === 200) {
          this.$store.dispatch('rules', response.data.roles)
        }
      }, error => {
        toast(this, 'danger', 'Erro ao obter regras', error.message)
        return Promise.reject(error)
      })
    },
    getGroups () {
      api.get('group/index').then((response) => {
        console.log(response.data)
        if (response.status === 200) {
          this.$store.dispatch('groups/init', response.data.groups)
        }
      }, error => {
        toast(this, 'danger', 'Erro ao obter users', error.message)
        return Promise.reject(error)
      })
    },
    getUsers () {
      api.get('user/index').then((response) => {
        console.log(response.data)
        if (response.status === 200) {
          this.$store.dispatch('users/init', response.data.users)
        }
      }, error => {
        toast(this, 'danger', 'Erro ao obter users', error.message)
        return Promise.reject(error)
      })
    },
    getMessages () {
      api.get('message/index').then((response) => {
        console.log(response.data)
        if (response.status === 200) {
          this.$store.dispatch('messages/init', response.data.messages)
        }
      }, error => {
        toast(this, 'danger', 'Erro ao obter as mensagens', error.message)
        return Promise.reject(error)
      })
    },
    callModal (modal, mode, item, fields, meta, extraFields, extraMode, method) {
      this.mode = mode
      this.item = item
      this.fields = fields
      this.meta = meta
      this.extraFields = extraFields
      this.extraMode = extraMode
      this.method = method
      this.$bvModal.show(modal)
    }
  },
  created () {
    this.getRules()
    this.getGroups()
    this.getUsers()
    this.getMessages()
  }
}
</script>
