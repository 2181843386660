<template>
    <b-modal size="xl" :title="!!mode ? meta.modes[mode].title : ''" id="form-modal" @ok="confirmSubmit">
        <b-form ref="form" @submit.stop.prevent="submit">
            <b-form-group v-for="field in editableFields" :key="field.key"
                :id="'input-add-group-' + field.key"
                :label="field.label + ':'"
                :label-for="'input-add-' + field.key"
                :description="field.description"
            >
                <b-form-input v-if="['text', 'number', 'email', 'password'].includes(field.type)"
                :id="'input-add-' + field.key"
                v-model="form[field.key]"
                v-maska="field.mask"
                :type="field.type"
                :min="field.min"
                :max="field.max"
                :required="!field.optional"
                ></b-form-input>

                <!--
                    <b-form-textarea v-if="field.type === 'textarea'"
                :id="'input-add-' + field.key"
                v-model="form[field.key]"
                placeholder="Conteúdo ..."
                rows="3"
                max-rows="12"
                ></b-form-textarea>
                -->

                <vue-editor v-if="field.type === 'textarea'"
                :id="'input-add-' + field.key"
                v-model="form[field.key]"
                ></vue-editor>

                <b-form-select v-if="field.type === 'select'"
                    v-model="form[field.key]"
                    :options="!Array.isArray(field.options) ? toOptions(dataState(field), field.options) : field.options" :multiple="!!field.multiple"
                >
                    <template #first>
                        <b-form-select-option :value="undefined" disabled>-- Selecione um {{ field.label }} --</b-form-select-option>
                    </template>
                </b-form-select>
            </b-form-group>
            <div v-for="field in hiddenFields" :key="field.key">
                <input type="hidden" :value="getValue(field)" />
            </div>
        </b-form>
    </b-modal>
</template>

<script>

import { toast, toOptions } from '@/utils'
import api from '@/services/condinvest'
import { VueEditor } from 'vue2-editor'

export default {
  components: {
    VueEditor
  },
  data: () => ({
    form: {}
  }),
  watch: {
    item (value, old) {
      // if (value !== null) {
      console.log('edit', value)
      this.form = Object.assign({}, value)
      // }
    },
    fields (value, olds) {
      // fix multiple select binding
      value.forEach(element => {
        if (element.type === 'select' && element.multiple) {
          console.log(element)
          this.form[element.key] = []
        }
      })
    }
  },
  computed: {
    allFields () {
      return this.fields.concat(this.extraFields ?? []).filter(a => a.validator !== undefined ? a.validator(this) : true)
    },
    hiddenFields () {
      return this.allFields.filter(f => f.type === 'hidden')
    },
    editableFields () {
      return this.allFields.filter(f => f.type !== 'hidden')
    },
    mainForm () {
      return Object.keys(this.form)
        .filter(key => this.fields.map(k => k.key).includes(key))
        .reduce((obj, key) => {
          obj[key] = this.form[key]
          return obj
        }, {})
    },
    extraForm () {
      return Object.keys(this.form)
        .filter(key => this.extraFields.map(k => k.key).includes(key))
        .reduce((obj, key) => {
          obj[key] = this.form[key]
          return obj
        }, {})
    }
  },
  props: {
    item: {
      required: false,
      type: Object
    },
    fields: {
      required: true,
      type: Array
    },
    meta: Object,
    mode: String,
    extraFields: {
      required: false,
      type: Array
    },
    extraMode: {
      type: String,
      required: false
    }
  },
  methods: {
    dataState (field) {
      if (field.module === undefined) {
        return this.$store.state[this.meta.module][this.meta.state]
      }
      return this.$store.state[field.module][field.state]
    },
    confirmSubmit (e) {
      e.preventDefault() // Prevent modal from closing
      this.submit()
    },
    getValue (field) {
      const value = field.value
      if (typeof value === 'string') {
        this.form[field.key] = value
      }
      if (value.self !== undefined && this.mode !== 'add') {
        this.form[field.key] = this.item[value.self]
      }
      if (value.store !== undefined) {
        let data = this.$store
        value.store.split('.').forEach(element => {
          data = data[element]
        })
        // console.log(data)
        this.form[field.key] = data
      }
      return this.form[field.key]
    },
    submit () {
      if (!this.$refs.form.checkValidity()) {
        toast(this, 'primary', 'Formulário Inválido', 'Preencha os campos corretamente')
        return
      }

      let requestConfig = this.meta.modes[this.mode]

      let request = requestConfig.method === 'post' ? api.post : api.put

      request(requestConfig.urn, this.item ? { id: this.item.id, user_id: this.item.id, ...this.mainForm } : this.mainForm).then((response) => {
        console.log(response)
        if ([200, 201].includes(response.status)) {
          const data = response.data[requestConfig.response]

          if (!this.mode.noDispatch) {
            if (this.mode === 'add') {
              this.$store.dispatch(this.meta.module + '/add', data)
            }
            if (this.mode === 'edit') {
              this.$store.dispatch(this.meta.module + '/edit', data)
            }
          }

          // Hide the modal manually
          this.$nextTick(() => {
            this.$bvModal.hide('form-modal')
          })

          toast(this, 'success', 'Sucesso', requestConfig.success)
          if (!(this.extraFields && this.extraMode && this.mode !== 'edit')) this.$router.go()
        }

        // Temporary solution until refactor of dashboard by new version
        if (this.extraFields && this.extraMode && this.mode !== 'edit') {
          requestConfig = this.meta.modes[this.extraMode]
          request = requestConfig.method === 'post' ? api.post : api.put

          const obj = this.extraForm
          obj.user_id = response.data.user.id

          request(requestConfig.urn, obj).then(response => {
            if ([200, 201].includes(response.status)) {
              toast(this, 'success', 'Sucesso', requestConfig.success)
              this.$router.go()
            }
          }, error => {
            toast(this, 'danger', 'Error ' + this.meta.title, error.message +
                (error.response.status === 422 ? ' => ' + error.response.data.errors : ''))
            return Promise.reject(error)
          })
        }
        /// /////////////////////////////////////////////////////////////////
      }, error => {
        toast(this, 'danger', 'Error ' + this.meta.title, error.message +
        (error.response.status === 422 ? ' => ' + error.response.data.errors : ''))
        return Promise.reject(error)
      })
    },
    toOptions: toOptions
  }
}
</script>
