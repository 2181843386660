export default {
  namespaced: true,
  state: {
    users: []
  },
  mutations: {
    set: (state, payload) => {
      state.users = payload
    },
    unshift: (state, payload) => {
      state.users.unshift(payload)
    },
    swap: (state, payload) => {
      state.users.forEach((element, index) => {
        if (element.id === payload.id) {
          state.users.splice(index, 1, payload)
        }
      })
    }
  },
  actions: {
    init: (context, payload) => {
      context.commit('set', payload)
    },
    add: (context, payload) => {
      context.commit('unshift', payload)
    },
    edit: (context, payload) => {
      context.commit('swap', payload)
    }
  }
}
