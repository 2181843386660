import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

// Dashboard
import Dashboard from '../views/Dashboard'

// Login
import Login from '../views/Login.vue'

Vue.use(VueRouter)

const routes = [
  // Dashboard
  {
    path: '/',
    component: Dashboard,
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('@/views/Dashboard/Home.vue')
      },
      {
        path: 'users',
        name: 'Users',
        component: () => import('@/views/Dashboard/Users.vue')
      },
      {
        path: 'users/group/:group',
        name: 'UsersFromGroups',
        component: () => import('@/views/Dashboard/UsersFromGroup.vue'),
        props: route => ({ groupId: route.params.group })
      },
      {
        path: 'users/devedores',
        name: 'Devedores',
        component: () => import('@/views/Dashboard/Devedores.vue')
      },
      {
        path: 'users/condominos',
        name: 'Condominos',
        component: () => import('@/views/Dashboard/Condominos.vue')
      },
      {
        path: 'condominios',
        name: 'Condominios',
        component: () => import('@/views/Dashboard/Condominios.vue')
      },
      {
        path: 'users/cobranca',
        name: 'Cobranca',
        component: () => import('@/views/Dashboard/Cobranca.vue')
      },
      {
        path: 'groups',
        name: 'Groups',
        component: () => import('@/views/Dashboard/Groups.vue')
      },
      {
        path: 'messages',
        name: 'Messages',
        component: () => import('@/views/Dashboard/Messages.vue')
      },
      {
        path: 'messages/template',
        name: 'MessagesTemplate',
        component: () => import('@/views/Dashboard/MessagesUsers.vue')
      },
      {
        path: 'rules',
        name: 'Rules',
        component: () => import('@/views/Dashboard/Rules.vue')
      },
      {
        path: 'boletos/:name/:id',
        name: 'Boleto',
        component: () => import('@/views/Dashboard/Boletos.vue')
      }
    ]
  },
  // Login
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: (to, from, next) => {
      if (store.getters.isLogged) {
        next({ name: 'Home' })
        return
      }
      next()
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  console.log(to.name)
  if (to.name !== 'Login' && !store.getters.isLogged) {
    next({ name: 'Login' })
    return
  }
  next()
})

export default router
