export default {
  namespaced: true,
  state: {
    groups: []
  },
  mutations: {
    set: (state, payload) => {
      state.groups = payload
    },
    unshift: (state, payload) => {
      state.groups.unshift(payload)
    },
    swap: (state, payload) => {
      state.groups.forEach((element, index) => {
        if (element.id === payload.id) {
          state.groups.splice(index, 1, payload)
        }
      })
    }
  },
  actions: {
    init: (context, payload) => {
      context.commit('set', payload)
    },
    add: (context, payload) => {
      context.commit('unshift', payload)
    },
    edit: (context, payload) => {
      context.commit('swap', payload)
    }
  }
}
