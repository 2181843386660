<template>
    <b-modal :title="'Deletar'" id="delete-modal" hide-footer>
      Tem certeza que deseja deletar esse item?<br>
      <div style="float: right">
        <b-button class="mr-2" variant="info" @click="cancel()">Cancelar</b-button>
        <b-button variant="danger" @click="ok()">Sim</b-button>
      </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    item: {
      required: true
    },
    title: String,
    state: String,
    method: Function
  },
  methods: {
    cancel () {
      this.$bvModal.hide('delete-modal')
    },
    ok () {
      console.log(this.method)
      this.method(this.item)
    }
  }
}
</script>
