<template>
    <div>
        <div class="menu-top">
            <div class="icon">
                <img src="@/assets/images/icon-white.png"/>
            </div>
            <div class="after-icon">
                <div class="profile d-flex flex-column justify-content-center" @click="profile">
                    <span class="profile-name">{{ this.$store.state.user.name }}</span>
                    <span class="profile-email">{{ this.$store.state.user.email }}</span>
                </div>
            </div>
        </div>

        <b-modal id="profile" hide-footer>
            <template #modal-title>Perfil</template>
            <div class="d-flex flex-column text-center">
                <span class="profile-title-name">{{ this.$store.state.user.name }}</span>
                <span class="profile-title-email">{{ this.$store.state.user.email }}</span>
            </div>
            <b-list-group class="mt-4 profile-list">
                <b-list-group-item @click="editProfile" class="d-flex align-items-center justify-content-between"><div class="d-flex"><i class="material-icons pr-2">edit</i><span>Editar Perfil</span></div><i class="material-icons arrow">keyboard_arrow_right</i></b-list-group-item>
                <b-list-group-item @click="changePassword" class="d-flex align-items-center justify-content-between"><div class="d-flex"><i class="material-icons pr-2">lock</i><span>Alterar Senha</span></div><i class="material-icons arrow">keyboard_arrow_right</i></b-list-group-item>
                <b-list-group-item @click="logout" class="d-flex align-items-center justify-content-between"><div class="d-flex"><i class="material-icons pr-2">logout</i><span>Sair</span></div><i class="material-icons arrow">keyboard_arrow_right</i></b-list-group-item>
            </b-list-group>
        </b-modal>

        <b-modal id="edit-profile" hide-footer>
            <template #modal-title>Editar Perfil</template>
            <b-form @submit.prevent="editProfileSubmit">

                <b-form-group
                    label="Nome"
                    label-for="modal-edit-nome"
                >
                    <b-form-input
                    id="modal-edit-nome"
                    v-model="modalEdit.name"
                    type="text"
                    placeholder="Nome"
                    required
                    ></b-form-input>
                </b-form-group>

                <b-form-group
                    label="E-mail"
                    label-for="modal-edit-email"
                >
                    <b-form-input
                    id="modal-edit-email"
                    v-model="modalEdit.email"
                    type="email"
                    placeholder="E-mail"
                    required
                    ></b-form-input>
                </b-form-group>

                <b-form-group
                    label="Telefone"
                    label-for="modal-edit-telefone"
                >
                    <b-form-input
                    id="modal-edit-telefone"
                    v-model="modalEdit.telephone"
                    v-maska="'(##) #####-####'"
                    type="text"
                    placeholder="Telefone"
                    required
                    ></b-form-input>
                </b-form-group>

              <b-button class="float-right" variant="primary" type="submit">Alterar</b-button>
          </b-form>

        </b-modal>

        <b-modal id="change-password" hide-footer>
            <template #modal-title>Alterar a Senha</template>
            <b-form @submit.prevent="newPassword">

                <b-form-group
                    label="Senha"
                    label-for="modal-password"
                >
                    <b-form-input
                    id="modal-password"
                    v-model="modalPassword.password"
                    type="password"
                    placeholder="Senha"
                    required
                    ></b-form-input>
                </b-form-group>

                <b-form-group
                    label="Confirmar Senha"
                    label-for="modal-password-c"
                >
                    <b-form-input
                    id="modal-password-c"
                    v-model="modalPassword.password_confirmation"
                    type="password"
                    placeholder="Confirmar Senha"
                    required
                    ></b-form-input>
                </b-form-group>

              <b-button class="float-right" variant="primary" type="submit">Alterar</b-button>
          </b-form>
        </b-modal>

        <div class="menu-aside">
            <div class="bottom-icon">

                <router-link :to="{name: 'Home'}" tag="div" active-class="current-page" exact>
                    <i class = "material-icons">home</i>
                    <span>Home</span>
                </router-link>

                <router-link v-if="rules.includes('index.user.api')" :to="{name: 'Users'}" tag="div" exact active-class="current-page">
                    <i class = "material-icons">person</i>
                    <span>Usuários</span>
                </router-link>

                <router-link v-if="rules.includes('index.user.condomino.api')" :to="{name: 'Condominos'}" tag="div" active-class="current-page">
                    <i class = "material-icons">person</i>
                    <span>Condôminos</span>
                </router-link>

                <router-link v-if="rules.includes('index.condominium.api')" :to="{name: 'Condominios'}" tag="div" active-class="current-page">
                    <i class = "material-icons">house</i>
                    <span>Condomínios</span>
                </router-link>

                <router-link v-if="rules.includes('index.user.cobranca.api')" :to="{name: 'Cobranca'}" tag="div" active-class="current-page">
                    <i class = "material-icons">person</i>
                    <span>Cobrança</span>
                </router-link>

                <router-link v-if="rules.includes('index.group.api')" :to="{name: 'Groups'}" tag="div" active-class="current-page">
                    <i class = "material-icons">group</i>
                    <span>Grupos</span>
                </router-link>

                <router-link v-if="rules.includes('index.message.api')" :to="{name: 'Messages'}" tag="div" active-class="current-page" exact>
                    <i class = "material-icons">chat</i>
                    <span>Mensagens</span>
                </router-link>

                <router-link v-if="rules.includes('index.message.api')" :to="{name: 'MessagesTemplate'}" tag="div" active-class="current-page">
                    <i class = "material-icons">chat</i>
                    <span>Mensagens Template</span>
                </router-link>

                <router-link v-if="rules.includes('index.role.api')" :to="{name: 'Rules'}" tag="div" active-class="current-page">
                    <i class = "material-icons">rule</i>
                    <span>Regras</span>
                </router-link>

            </div>
            <div class="slot-page">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<style lang="stylus" scoped>

    @import '../../style/colors.styl';
    @import '../../style/fonts.styl';
    @import '../../style/mixins.styl';

    .menu-top
    .menu-aside
        display flex

    .icon
        background-color primary
        padding 8px
        flex-shrink 0
        cursor pointer
        transition 0.4s

        img
            width 48px
            height 48px

        &:hover
            background-color alpha(primary-dark, 0.7)

    .after-icon
    .bottom-icon
        background-color secondary

    .after-icon
        width 100%

    .slot-page
        width 100%

    .bottom-icon
        width (48+8*2)px
        min-height calc(100vh - 64px)
        flex-shrink 0

        > div
            padding 12px 0
            cursor pointer
            transition 0.3s

            &:hover
                background-color alpha(primary-dark, 0.7)

        .current-page
            background-color alpha(primary, 0.8)

            &:hover
                background-color alpha(primary, 0.5)

        .material-icons, span
            display block
            color #EEE
            width 100%
            text-align center
            no-select()

        .material-icons
            font-size 28px

        span
            font-size 11px

    .after-icon
        display flex
        align-items center

        .profile
            color white
            margin-left auto
            order 2
            font-size .75em
            height 100%
            cursor pointer
            padding 0 24px

            &:hover
                background-color lighten(secondary, 8%)

            .profile-name
                font-weight bold

            .profile-email
                text-decoration underline

    .slot-page
        padding 15px 25px

    .profile-title-name
        font-size 1.4em
        font-weight bold

    .profile-title-email
        font-size 1.2em
        text-decoration underline
        color primary-dark

    .profile-list
        no-select()

        .list-group-item
            cursor pointer
            border none

            &:hover
                background-color rgba(0,0,0,.125)

                .arrow
                    color rgba(0,0,0,.5)

        .arrow
            color rgba(0,0,0,.2)

</style>

<script>

import api from '@/services/condinvest'
import { toast } from '@/utils'

export default {
  data: () => ({
    modalPassword: {
      password: '',
      password_confirmation: ''
    },
    modalEdit: {}
  }),
  methods: {
    logout: function () {
      api.get('auth/logout', this.user).then(response => {
        console.log(response)
      })
      this.$store.dispatch('logout')
      this.$router.replace({ name: 'Login' })
    },
    profile () {
      this.$bvModal.show('profile')
    },
    changePassword () {
      this.$bvModal.hide('profile')
      this.$bvModal.show('change-password')
    },
    editProfile () {
      this.modalEdit = {
        name: this.$store.state.user.name,
        email: this.$store.state.user.email,
        telephone: this.$store.state.user.telephone
      }
      this.$bvModal.hide('profile')
      this.$bvModal.show('edit-profile')
    },
    newPassword () {
      api.post('reset_password', { password: this.modalPassword.password, password_confirmation: this.modalPassword.password_confirmation }).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          this.$bvModal.hide('change-password')
          toast(this, 'success', 'Alteração de Senha', 'Senha alterada com sucesso')
        }
      }, error => {
        toast(this, 'danger', 'Erro', error.message)
        return Promise.reject(error)
      })
    },
    editProfileSubmit () {
      api.post('/auth/update/' + this.$store.state.user.id, this.modalEdit).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          this.$bvModal.hide('edit-profile')
          this.$store.dispatch('profile', this.modalEdit)
          toast(this, 'success', 'Alteração de perfil', 'Perfil alterado com sucesso')
        }
      }, error => {
        toast(this, 'danger', 'Erro', error.message)
        return Promise.reject(error)
      })
    }
  },
  computed: {
    rules () {
      return this.$store.state.rules.map(a => a.key)
    }
  }
}
</script>
